import React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import "./CalendarWrapper.scss";
import iconBedSmall from "./images/icon_bed_small.svg";
import iconGasSmall from "./images/icon_gas_small.svg";
import iconRouteSmall from "./images/icon_route_small.svg";
import * as constants from "./constants";
import * as helper from "./helper";
import arrowNext from "./images/arrow_next.svg";
import arrowPrev from "./images/arrow_prev.svg";

export default class CalendarWrapper extends React.Component {
  calendarRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      dayGridMonth: {
        activeStart: new Date(),
      },
    };
    console.log(props);
    this.appInterface = props.appInterface;

    this.onDateSelect = this.onDateSelect.bind(this);
    this.onDateClick = this.onDateClick.bind(this);
  }
  render() {
    return (
      <>
        {this.getInputDateControls()}
        <FullCalendar
          ref={this.calendarRef}
          headerToolbar={{ start: "", center: "", end: "" }}
          plugins={[dayGridPlugin, interactionPlugin]}
          contentHeight={500}
          initialView="dayGridMonth"
          eventContent={renderEventContent}
          dateClick={this.onDateClick}
          selectable={true}
          events={this.props.events}
          eventClick={this.onEventClick.bind(this)}
          showNonCurrentDates={false}
        />
      </>
    );
  }
  onEventClick(info) {
    console.log("onEventClick:", info);
    var selectedEventInfo = { ...info.event.extendedProps };
    selectedEventInfo.selectedDate = info.event.start;
    selectedEventInfo.dateType = "day";
    selectedEventInfo.id = selectedEventInfo.dataEvent.id;
    if (selectedEventInfo.type === "expense") {
      selectedEventInfo.formTitle = " Δαπάνη";
    } else if (selectedEventInfo.type === "route") {
      selectedEventInfo.formTitle = " Διαδρομή / Διαμονή";
    }
    selectedEventInfo.formBut0Title = "ΑΠΟΘΗΚΕΥΣΗ";
    selectedEventInfo.formBut0Action = "save";
    selectedEventInfo.formBut1Title = "ΔΙΑΓΡΑΦΗ";
    selectedEventInfo.formBut1Action = "delete";
    selectedEventInfo.canChangeDate = false;

    this.appInterface.onEventClick(selectedEventInfo);
  }
  onDateSelect(selectionInfo) {
    console.log(selectionInfo);
  }
  onDateClick(info) {
    console.log("onDateClick");
    this.appInterface.onDateClick(info);
    console.log(info);
  }
  onMonthDatePrevClick() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.prev();
    this.monthsToAddToSelectedDate(-1, () => this.timeToNotifyDidChangeMonth());
  }
  onMonthDateNextClick() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.next();
    this.monthsToAddToSelectedDate(1, () => this.timeToNotifyDidChangeMonth());
  }
  timeToNotifyDidChangeMonth() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(this.state.dayGridMonth.activeStart)
    this.props.appInterface.onDidChangeMonth(
      this.state.dayGridMonth.activeStart
    );
  }
  monthsToAddToSelectedDate(monthsToAdd, callback) {
    var resultDate = new Date(this.state.dayGridMonth.activeStart);
    var resultDateMonth=resultDate.getMonth();
    resultDate.setMonth(resultDate.getMonth() + monthsToAdd);
    var newResultDateMonth=resultDate.getMonth();
    if(resultDateMonth==newResultDateMonth){
      resultDate.setMonth(resultDate.getMonth() + monthsToAdd);
    }

    var dayGridMonth = { ...this.state.dayGridMonth };

    dayGridMonth.activeStart = resultDate;

    this.setState({ dayGridMonth: dayGridMonth }, callback);
  }
  getInputDateControls() {
    var butPrev = (
      <button className="but_prev" onClick={() => this.onMonthDatePrevClick()}>
        <img src={arrowPrev} alt="" />
      </button>
    );
    var butNext = (
      <button className="but_next" onClick={() => this.onMonthDateNextClick()}>
        <img src={arrowNext} alt="" />
      </button>
    );
    var todayMonthAlias = parseInt(helper.getMonthAlias(new Date()));
    var currentMonthAlias = "";
    if (this.props.selectedMonth && this.props.selectedMonth.alias) {
      currentMonthAlias = parseInt(this.props.selectedMonth.alias);
    } else {
      currentMonthAlias = todayMonthAlias;
    }
    if (currentMonthAlias == todayMonthAlias) {
      butNext = <></>;
    }
    if (currentMonthAlias == 202107) {
      butPrev = <></>;
    }

    return (
      <div className="form_input_date_controls">
        {butPrev}
        {this.getCurrentDateDisplay()}
        {butNext}
      </div>
    );
  }
  getCurrentDateDisplay() {
    var date = this.state.dayGridMonth.activeStart;
    var day = date.getDate();
    var year = date.getFullYear();
    var monthFullName = constants.monthFullNames[date.getMonth()];

    return (
      <span>
        <span>
          <strong>{monthFullName}</strong> {year}{" "}
        </span>
      </span>
    );
  }
}

function renderEventContent(eventInfo) {
  var extendedProps = eventInfo.event.extendedProps;
  if (extendedProps.type === "expense") {
    return (
      <>
        <div className={"event_container event_expense_container "+(extendedProps.withReplacementCar?"red_border":"")}>
          <div className="event_content event_expense_content">
            <img src={iconGasSmall} alt="" />
            <div className="event_values">
              <span>
                <span className="green">
                  {helper.formatNumber2Digits(extendedProps.cost)}
                </span>
                <span>€</span>
              </span>
              <span>
                <span className="orange">
                  {helper.formatNumber2Digits(extendedProps.amount)}
                </span>
                <span>lt</span>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    var imgBed = <span>&nbsp;</span>;
    if (extendedProps.didStay) {
      imgBed = <img src={iconBedSmall} alt="" />;
    }
    return (
      <>
        <div className={"event_container event_route_container "+(extendedProps.withReplacementCar?"red_border":"")}>
          <div className="event_content event_content_row event_route_content">
            <div className="event_content_top_row">
              <img src={iconRouteSmall} alt="" />
              <div className="event_values">
                <span>
                  <span className="blue">
                    {helper.formatNumber0Digits(extendedProps.distance)}
                  </span>
                  <span>km</span>
                </span>
                {imgBed}
              </div>
            </div>

            <div className="destination">{extendedProps.destination}</div>
          </div>
        </div>
      </>
    );
  }
}
