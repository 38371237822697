import React from 'react';

import Modal from 'react-bootstrap/Modal';


import './FormSelectType.scss';

import iconRoute from './images/icon_route.svg';
import iconGas from './images/icon_gas.svg';

import arrowNext from './images/arrow_next.svg';
import arrowPrev from './images/arrow_prev.svg';
import iconExit from './images/icon_exit.svg';
import * as constants from  './constants';
import * as helper from "./helper";

export default class FormSelectTypeModal extends React.Component{
    constructor(props)
    {
        
        super(props);
        this.appInterface=props.appInterface;
        console.log(props);
    }
    render() {
        var butDatePrev=<button className="but_prev" onClick={() => this.appInterface.onButDatePrevClick()}><img src={arrowPrev} alt="" /></button>
      
        var butDateNext=<button className="but_next" onClick={() => this.appInterface.onButDateNextClick()}> <img src={arrowNext} alt="" /></button>
        
        var selectedMonthAlias=helper.getMonthAlias(this.props.selectedInfo.selectedDate);
        var nextDate=helper.getDateByAddingDaysToDate(1,this.props.selectedInfo.selectedDate);
        var nextMonthAlias=helper.getMonthAlias(nextDate);
  
        if(nextMonthAlias!=selectedMonthAlias)
        {
          butDateNext=<></>
        }
        
        var prevDate=helper.getDateByAddingDaysToDate(-1,this.props.selectedInfo.selectedDate);
        var prevMonthAlias=helper.getMonthAlias(prevDate);
        if(prevMonthAlias!=selectedMonthAlias)
        {
          butDatePrev=<></>
        }

        return (
        <Modal
            {...(({ show, onHide }) => ({ show, onHide }))(this.props)}
            
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>

                <div className="form_type_options">
                    <div className="type_options_header">
                        {butDatePrev}
                        {this.getCurrentDateDisplay()}
                        {butDateNext}
                    </div>
                    <button className="but_form_exit" onClick={this.props.onHide}> 
                        <img src={iconExit} alt=""/>
                    </button>
                    <div className="type_options_container">
                        <button className="type_option" onClick={()=>this.appInterface.onButExpenseSelected()}>
                            <div className="option_icon_container">
                                <img src={iconGas} alt=""/>
                            </div>
                            
                            <div className="option_footer bg_green">
                                Προσθήκη<br/>Δαπάνης
                            </div>
                        </button>
                        <div className="type_option_seperator"></div>
                        <button className="type_option" onClick={()=>this.appInterface.onButRouteSelected()}>
                            <div className="option_icon_container">
                                <img src={iconRoute} alt=""/>
                            </div>
                            <div className="option_footer bg_blue">
                                Προσθήκη<br/>Διαδρομής / Διαμονής
                            </div>
                        </button>
                    </div>
                </div>
                

            </Modal.Body>
        </Modal>
        );
    }
    getCurrentDateDisplay()
    {
        var date=this.props.selectedInfo.selectedDate;
        var day=date.getDate();
        var year=date.getFullYear();
        var monthFullName=constants.monthFullFromNames[date.getMonth()];
        return (
            <span>
                <span><strong>{day} {monthFullName}</strong> {year} </span>
            </span>
        );
    }
  }