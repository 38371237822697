import * as helper from  './helper';
import * as constants from  './constants';

export function handleReceivedData(data,app)
  {
    if(data.status!=="success")
    {
      if(data.errors[0]==="Invalid User")
      {
        localStorage.setItem(constants.localStorageTokenKey,"");
        app.token=null;
        app.setState({isLoggedIn:false});


      }
      return;
    }
    var user=app.state.user;
    var token=app.token;
    var newToken=app.token;
    var vehicles=app.state.vehicles;
    var firstVehicle={};
    var lastVehicle={};
    if(data.token)
    {
      
      user.token=data.token;
      newToken=data.token;
      app.token=data.token;
    }
    if(data.user)
    {
      
      user=data.user;
      app.user=user;
      if(user.token)
      {
        newToken=user.token;
      }
    }
    if(newToken!==token)
    {
      app.token=newToken;
      localStorage.setItem(constants.localStorageTokenKey,newToken);
    }
      app.data=data;
      var i;
      var stateEvents=[];
      var months={...app.state.months}
      
      resetUnlockedMonths(months);

      var firstOpenMonthAlias=parseInt(app.state.firstOpenMonthAlias);

      var closedMonths=[];
      if(data.months)
      {
        var checkResult=checkToUpdateMonths(months,data.months,app);
        if(parseInt(checkResult.nextMonthFromMax)>200000)
        {
          firstOpenMonthAlias=parseInt(checkResult.nextMonthFromMax);
        }
        
      }
      var vehicleManipulationResult=manipulateVehicles(data,vehicles);

      vehicles=vehicleManipulationResult.vehicles;
      firstVehicle=vehicleManipulationResult.firstVehicle;
      lastVehicle=vehicleManipulationResult.lastVehicle;
      
      
      for(i=0;i<data.events.length;i++)
      {
          var dataEvent=data.events[i];
          
          var stateEvent={...dataEvent};
          stateEvents.push(stateEvent);
          stateEvent.dataEvent=dataEvent;
          stateEvent.title='';
          stateEvent.type=dataEvent.type;
          stateEvent.dateType="day";
          stateEvent.backgroundColor="transparent";
          stateEvent.borderColor="transparent";
          if(dataEvent.didStay==="1")
          {
            stateEvent.didStay=true;
          }
          else if(dataEvent.didStay==="0")
          {
            stateEvent.didStay=false;
          }
          stateEvent.withReplacementCar=false;
          if(dataEvent.with_replacement_car==="1")
          {
            stateEvent.withReplacementCar=true;
          }
          
          stateEvent.locked=parseInt(stateEvent.locked);

          var date=new Date(dataEvent.timestamp*1000);
          var yearStr=helper.formatInteger(date.getFullYear(),4);
          var monthStr=helper.formatInteger(date.getMonth()+1,2);
          var dateStr=yearStr
          +"-"+monthStr
          +"-"+helper.formatInteger(date.getDate(),2)
          stateEvent.date=dateStr;
          stateEvent.monthAlias=yearStr+monthStr;
          checkToAddEventToMonth(months,stateEvent)

          if(stateEvent.locked!==1)
          {
            if(parseInt(stateEvent.monthAlias)<firstOpenMonthAlias || firstOpenMonthAlias===0)
            {
              firstOpenMonthAlias=stateEvent.monthAlias;
            }
          }

      }
      calculateNewMonths(months,vehicles);
      
      var newState={
        isLoggedIn:true,
        events:stateEvents,
        user:user,
        vehicles:vehicles,
        months:months,
        firstOpenMonthAlias:firstOpenMonthAlias,
        firstVehicle:firstVehicle,
        lastVehicle:lastVehicle
      };
      app.setState(newState,()=>app.updateCurrentMonthStateFromDate(app.state.monthDate));

  }
  function resetUnlockedMonths(months)
  {
    var i;
    for (const [key, month] of Object.entries(months)) {
      if(month.locked)
      {
        continue;
      }
      month.allEvents=[];
      month.total_expenses=0;
      month.total_distance=0;
      month.excess=0;
      month.compensation=0;
      month.expenses={};
      month.routes={};
      month.shouldCalculate=true;
    }
  }

  export function manipulateVehicles(data,oldVehicles)
  {
    var result={};
    var vehicles=oldVehicles;
    var firstVehicle=null;
    var lastVehicle=null;
    if(data.vehicles)
      {
        vehicles=data.vehicles;

        for(let i=0;i<vehicles.length;i++)
        {
          var vehicle=vehicles[i];
          vehicle.consumption=parseFloat(vehicle.consumption);
          vehicle.timestamp_start=parseInt(vehicle.timestamp_start);
          vehicle.timestamp_end=parseInt(vehicle.timestamp_end);
          vehicle.odometer_current=parseInt(vehicle.odometer_current);
          vehicle.odometer_initial=parseInt(vehicle.odometer_initial);
          if(vehicle.odometer_current<vehicle.odometer_initial)
          {
            vehicle.odometer_current=vehicle.odometer_initial;
          }

          if((firstVehicle && vehicle.timestamp_start<firstVehicle.timestamp_start) || !firstVehicle)
          {
            firstVehicle=vehicle;
          }

          if((lastVehicle && vehicle.timestamp_start>firstVehicle.timestamp_start) || !lastVehicle)
          {
            lastVehicle=vehicle;
          }
        }
        
      }
      result.vehicles=vehicles;
      result.firstVehicle=firstVehicle;
      result.lastVehicle=lastVehicle;
      
      return result;
  }
  
  export function checkToUpdateMonths(months,dataMonths,app)
  {
    var i;
    var maxAlias=0;

    for(i=0;i<dataMonths.length;i++)
    {
      var dataMonth=dataMonths[i];
      dataMonth.locked=parseInt(dataMonth.locked);
      var numDataMonthAlias=parseInt(dataMonth.alias);
      if(numDataMonthAlias>maxAlias)
      {
        maxAlias=numDataMonthAlias
      }
      
      
      var month=months[dataMonth.alias];
      
      if(!month)
      {
        month={...dataMonth};
        
        months[month.alias]=month;
      }
      else
      {
        for (const [key, value] of Object.entries(dataMonth)) {
          month[key]=value;
        }
      }
      if(app.state.selectedMonth && app.state.selectedMonth.locked)
      {
        if(app.state.selectedMonth.alias==month.alias)
        {
          month.hasBeenReceived=true;
        }
      }
    }

    var nextMonthFromMax=helper.getNextMonthAliasFromAlias(helper.formatInteger(maxAlias,6));
    var result={};
    result.nextMonthFromMax=nextMonthFromMax;
    return result;
  }

  export function checkToAddEventToMonth(months,stateEvent)
  {
    
    var month=months[stateEvent.monthAlias];
    if(!month)
    {

      month={ total_expenses:0,
        total_distance:0,
        excess:0,
        compensation:0,
        expenses:{},
        routes:{},
        alias:stateEvent.monthAlias,
        timestamp_start:helper.getDateStartFromMonthAlias(stateEvent.monthAlias)
      };
      
      month.alias=stateEvent.monthAlias;
      months[stateEvent.monthAlias]=month;

    }
    month.shouldCalculate=true;
    if(stateEvent.type==="expense")
    {
      if(!month.newExpenses)
      {
        month.newExpenses={};
      }
      month.newExpenses[stateEvent.id]=stateEvent;
    }
    else if(stateEvent.type==="route")
    {
      if(!month.newRoutes)
      {
        month.newRoutes={};
      }
      month.newRoutes[stateEvent.id]=stateEvent;
    }


  }
  export function calculateNewMonths(months,vehicles)
  {
    for (const [key, month] of Object.entries(months)) {
      setVehicleToMonth(month,vehicles);
      if(month.shouldCalculate===true)
      {

        calculateMonth(month,vehicles);
      }
    }
  }
export function setVehicleToMonth(month,vehicles)
  {
    month.vehicle=null;
    for(var i=0;i<vehicles.length;i++)
    {
      var vehicle=vehicles[i];
      if( month.timestamp_start>=vehicle.timestamp_start && month.timestamp_start<vehicle.timestamp_end )
      {
        month.vehicle=vehicle;
        break;
      }
    }
    if(month.vehicle==null)
    {
      if(vehicles.length>0)
      {
        if(month.timestamp_start<vehicles[0].timestamp_start)
        {
          month.vehicle=vehicles[0];
        }
        else
        {
          month.vehicle=vehicles[vehicles.length-1];
        }
      }
    }
  }
export function calculateMonth(month,vehicles)
  {
    month.expenses=month.newExpenses;
    delete month.newExpenses;
    month.routes=month.newRoutes;
    delete month.newRoutes;
    month.allEvents=[];
    month.shouldCalculate=false;
    if(month.locked===1)
    {
      if(month.expenses)
      {
        for (const [key, event] of Object.entries(month.expenses)) {
          month.allEvents.push(event);
        }
      }
      if(month.routes)
      {
        for (const [key, event] of Object.entries(month.routes)) {
          month.allEvents.push(event);
        }
      }
      return;
    }

    
    


    month.total_distance=0;
    month.total_expenses=0;
    month.total_amount=0;
    month.excess=0;
    month.compensation=0;
    month.hasWithReplacementCar=false;
    if(month.expenses)
    {
      for (const [key, event] of Object.entries(month.expenses)) {
        if(event.withReplacementCar)
        {
          month.hasWithReplacementCar=true;
          month.allEvents.push(event);
          continue;
        }
        month.total_expenses=month.total_expenses+parseFloat(event.cost);
        month.total_amount=month.total_amount+parseFloat(event.amount);
        month.allEvents.push(event);
      }
    }
    if(month.routes)
    {
      for (const [key, event] of Object.entries(month.routes)) {
        if(event.withReplacementCar)
        {
          month.hasWithReplacementCar=true;
          month.allEvents.push(event);
          continue;
        }
        month.total_distance=month.total_distance+parseFloat(event.distance);
        month.allEvents.push(event);
      }
    }
    if(month.total_amount>0 && month.vehicle)
    {
      var valuePerLt=month.total_expenses/month.total_amount;
      var consumption=month.vehicle.consumption;
      month.excess=((month.total_distance/100)*consumption*valuePerLt)-month.total_expenses;
      month.compensation=month.total_expenses+month.excess;
      if(month.excess<0)
      {
        month.excess=(-1)*month.excess;
      }
      else
      {
        month.excess=0;
      }

    }
    if(!month.vehicle_odometer)
    {
      if(month.vehicle)
      {
        var odometer_initial=parseInt(month.vehicle.odometer_initial);
        var odometer_current=parseInt(month.vehicle.odometer_current);
        month.vehicle_odometer=Math.max(odometer_initial,odometer_current);
        
      }
      else
      {
        month.vehicle_odometer=0;
      }
    }

   
  }

  export function extractEventToSave(eventInfo)
  {
    var event={};
    if(eventInfo.id)
    {
      event.id=eventInfo.id;
    }
    event.type=eventInfo.type;
    
    var keys=["cost","distance","amount","destination","action","didStay"];
    var i;
    for(i=0;i<keys.length;i++)
    {
      var key=keys[i];
      if(eventInfo[key]!==undefined && eventInfo[key]!==null)
      {
        event[key]=eventInfo[key];
      }
    }
    if(eventInfo.withReplacementCar===true)
    {
      event.with_replacement_car=1;
    }
    else
    {
      event.with_replacement_car=0;
    }
    if(eventInfo.didStay===true)
    {
      event.didStay=1;
    }
    else if(eventInfo.didStay===false)
    {
      event.didStay=0;
    }


    event.timestamp=Math.round(eventInfo.selectedDate.getTime())/1000;
    return event;
  }
  export function getMinimumOdometerInfo(selectedMonth,app)
  {
    var minOdometer=0;
    if(selectedMonth.vehicle && selectedMonth.routes)
    {
      for (const [key,route] of Object.entries(selectedMonth.routes)) {
        if(parseInt(route.vehicle_id)===parseInt(selectedMonth.vehicle.id))
          {
            if(route.withReplacementCar)
            {
              continue;
            }
            minOdometer=minOdometer+parseInt(route.distance);
          }
      }
    }
    minOdometer=parseInt(selectedMonth.vehicle.odometer_current)+minOdometer;

    return minOdometer;
  }