import React from 'react'

import './MainHeader.scss';
import iconBurger from './images/icon_burger_menu.svg';
import iconContair from './images/icon_contair.svg';
import iconHumanFlag from './images/icon_human_flag.svg';
import iconFlag from './images/icon_flag.svg';
import iconDownload from './images/icon_download.svg';
import * as helper from  './helper';

export default class MainHeader extends React.Component {
    constructor(props)
    {
        super(props);
        console.log("mainHeader props",props);
    }
    render() {
      return (
        <div className='main_header_container'>
            <div className="main_header">
                <button className="but_hamburger" onClick={this.onBurgerPress.bind(this)}><img src={iconBurger} alt=""/></button>
                <div className="main_header_content">
                    <div className="main_header_content_left">
                        <div className="main_header_content_left_item">{this.getUserInfo()}</div>
                        <div className="main_header_content_left_item vehicle_info">{this.getVehicleInfo()}</div>
                        
                        <div className="main_header_content_left_item"><span className="blue plate_number"><strong>{this.getVehiclePlateInfo()}</strong></span>&nbsp;<img className="img_contair" src={iconContair} alt=""/><span>&nbsp;{this.props.selectedMonth.vehicle_odometer+" "}km</span></div>
                    </div>
                    <div className="summary">
                        <div className="summary_col">
                            <div className="summary_title">ΠΛΗΡΩΜΕΣ</div>
                            <div><span className="green">{helper.formatNumber2Digits(this.props.selectedMonth.total_expenses)}</span>&nbsp;€</div>
                        </div>
                        <div className="summary_col">
                            <div className="summary_title">ΑΠΟΖΗΜΙΩΣΗ</div>
                            <div><span className="green">{helper.formatNumber2Digits(this.props.selectedMonth.compensation)}</span>&nbsp;€</div>
                        </div>
                        <img src={iconHumanFlag} alt=""/>
                        <div className="summary_col">
                            <div className="summary_title">ΥΠΕΡΒΑΣΗ</div>
                            <div><span className="red">{helper.formatNumber2Digits(this.props.selectedMonth.excess)}</span>&nbsp;€</div>
                        </div>
                        <div className="summary_col">
                            <div className="summary_title">ΑΠΟΣΤΑΣΗ</div>
                            <div><span className="blue">{helper.formatNumber0Digits(this.props.selectedMonth.total_distance)}</span>&nbsp;KM</div>
                        </div>
                    </div>
                    {this.getCloseButton()}
                    
                </div>
                
            </div>
            <div className="header_seperator"></div>
        </div>
      )
    }
    onBurgerPress()
    {
        this.props.appInterface.openSideBar();
    }
    getUserInfo()
    {
        var fullName="";
        if(this.props.user.name && this.props.user.surname)
        {
            fullName=this.props.user.name+" "+this.props.user.surname;
        }

        return fullName;
    }
    getVehicleInfo()
    {
        var vehicleName="";
        if(this.props.selectedMonth.vehicle)
        {
            var vehicle=this.props.selectedMonth.vehicle;
            return vehicle.brand+" "+vehicle.model;
        }
        return vehicleName;
    }
    getVehiclePlateInfo()
    {
        var vehiclePlate="";
        if(this.props.selectedMonth.vehicle)
        {
            var vehicle=this.props.selectedMonth.vehicle;
            return vehicle.plate;
        }
        return vehiclePlate;
    }


    getCloseButton()
    {
        var firstMonthAlias=parseInt(this.props.firstOpenMonthAlias);
        var selectedMonthAlias=parseInt(this.props.selectedMonth.alias);

        if(firstMonthAlias===0 || selectedMonthAlias===firstMonthAlias)
        {
            return(<button className="but_close_month bg_blue"  onClick={()=>this.props.appInterface.onButCloseMonthPress()}><span>Kλείσιμο Μήνα</span><div className="seperator"></div><img src={iconFlag} alt=""></img></button>);
        }
        else if(this.props.selectedMonth.locked===1)
        {
            return(<button className="but_close_month bg_gray two_row_font_size" onClick={()=>this.props.appInterface.onButDownloadMonthPress()}  ><span>Ο μήνας <br/> έχει κλείσει</span><div className="seperator"></div><img width="30" src={iconDownload} alt=""></img></button>);
        }
        else if(selectedMonthAlias>firstMonthAlias && (selectedMonthAlias-firstMonthAlias)===1)
        {
            return(<button className="but_close_month bg_gray two_row_font_size"  ><span>Πρέπει πρώτα να κλείσει <br/> ο προηγούμενος μήνας</span><div className="seperator"></div><img src={iconFlag} alt=""></img></button>);
        }
        else if(selectedMonthAlias>firstMonthAlias)
        {
            return(<button className="but_close_month bg_gray two_row_font_size"  ><span>Πρέπει πρώτα να κλείσουν <br/> οι προηγούμενοι μήνες</span><div className="seperator"></div><img src={iconFlag} alt=""></img></button>);
        }
        else
        {
            return(<button className="but_close_month bg_gray two_row_font_size"  ><span>Αυτός ο μήνας δεν μπορεί <br/> να κλείσει</span><div className="seperator"></div><img src={iconFlag} alt=""></img></button>);
        }
        
    }
  }