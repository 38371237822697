export function formatInteger(num,digits)
{
    var numStr=parseInt(num+"")+"";
    while(numStr.length<digits)
    {
        numStr="0"+numStr;
    }
    return numStr;
}
export function formatNumber2Digits(num)
{
    num=parseFloat(num);
    num=Math.round(num*100)/100;
    var numStr=num+"";
    numStr=numStr.replaceAll(".",",");
    const numSplited = numStr.split(",");
    var result="";
    if(numSplited.length===1)
    {
        result=numSplited+",00";
    }
    else if(numSplited.length===0)
    {
        result= "0,00";
    }
    else if(numSplited.length>1)
    {
        var baseNum=numSplited[0];
        var fractional=numSplited[1];
        if(fractional.length>2)
        {
            fractional=fractional.substring(0, 2);
        }
        else if(fractional.length===1)
        {
            fractional=fractional+"0";
        }
        result= baseNum+","+fractional;
    }
    return result;
}
export function formatNumber2DigitsWithDot(num)
{
    var result = formatNumber2Digits(num);
    result=result.replaceAll(",",".");
    return result;

}
export function formatNumber0Digits(num)
{
    num=parseFloat(num);
    num=Math.round(num);
    var numStr=num+"";


    return numStr;
}
export function getDateByAddingDaysToDate(daysToAdd,date)
{
    var resultDate=new Date(date);
    resultDate.setDate(resultDate.getDate() + daysToAdd);
    return resultDate;
}
export function getMonthAlias(date)
{
    var yearStr=formatInteger(date.getFullYear(),4);
    var monthStr=formatInteger(date.getMonth()+1,2);      
    return yearStr+monthStr;

}
export function getNextMonthAliasFromAlias(monthAlias)
{
    var year=parseInt(monthAlias.substring(0,4));
    var month=parseInt(monthAlias.substring(4,6));
    month=month+1;
    if(month>12)
    {
        month=1;
        year=year+1;
    }
    return year+formatInteger(month,2);
}
export function getDateStartFromMonthAlias(monthAlias)
{
    var yearStr=monthAlias.substring(0,4);
    var monthStr=monthAlias.substring(4,6);

    var date=new Date(parseInt(yearStr),parseInt(monthStr)-1,1,0,0,0,0);
    
    return Math.round(date.getTime()/1000);
}


export async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //mode: 'no-cors', // no-cors, *cors, same-origin
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      //redirect: 'follow', // manual, *follow, error
      //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    console.log("response:",response);
    return response.json(); // parses JSON response into native JavaScript objects
  }