import React from 'react'
import * as helper from  './helper';
import * as constants from  './constants';
import * as dataMan from './data_man';
import FormSelectTypeModal from './FormSelectType';
import FormInputModal from './FormInputModal';
import SideBar from './SideBar'

import CalendarWrapper from './CalendarWrapper'
import MainHeader from './MainHeader'
import LoginScreen from './LoginScreen'
import RemindPasswordScreen from './RemindPasswordScreen';
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";


import './App.scss';
import iconLoading from './images/loading_small_transparent.gif';

class App extends React.Component {


  constructor(props)
  {
    super(props);
    
    this.state={
      modalShowFormSelectType:false,
      modalShowFormInput:false,
      isLoggedIn:false,
      shouldShowForgotPassword:false,
      events:[],
      vehicles:[],
      months:{},
      selectedMonth:{
        total_expenses:0,
        total_distance:0,
        excess:0,
        locked:0
      },
      openMonth:null,
      firstOpenMonthAlias:helper.getMonthAlias(new Date()),
      isLoading:false,
      selectedInfo:{

      },
      update:0,
      user:{
        token:"123456"
      },
      monthDate:new Date(),
      sideBarOpenClass:"",
      firstVehicle:{},
      lastVehicle:{}
      
    }

    this.submitMonthWithReplacementCarMessage=<span className="submit_with_replacement_car_message">Εχετε δηλώσει ότι έχετε αυτοκίνητο αντικατάστασης, παρακαλούμε μην παραλείψετε να αποστείλετε και το <a href="assets/replacement_car.xlsx" download>excel</a> με τις πληροφορίες του οχήματος</span>
    this.generalRequestError=<span class="red">Σφαλμα κατα την αποστολή. Ελένξτε την σύνδεσή σας στο internet και προσπαθήστε ξανά.</span>;
    this.token=localStorage.getItem(constants.localStorageTokenKey);
    //this.token="23klsdjfksjncljkhj32iu4234";
    if(this.token)
    {
      this.state.isLoggedIn=true;
    }

    this.defaultMonth=constants.defaultMonth;
    this.defaultRouteInfo=constants.defaultRouteInfo;
    this.defaultExpenseInfo=constants.defaultExpenseInfo;
    this.defaultCloseMonthInfo=constants.defaultCloseMonthInfo;
     
    this.state.selectedInfo=this.defaultCloseMonthInfo;
    var appInterface={};
    this.appInterface=appInterface;
    var self=this;

    this.selectedDate=new Date();


    appInterface.onDateClick=function(selectionInfo)
    {
      

      if(self.state.selectedMonth.locked)
      {
        return;
      }
      if(parseInt(self.state.selectedMonth.alias)<(self.state.firstOpenMonthAlias) && self.hasClosedMonths())
      {
        return;
      }
      self.selectedDate=selectionInfo.date;
      var selectedInfo={};
      selectedInfo.selectedDate=selectionInfo.date;
      
      self.setState({modalShowFormSelectType:true,selectedInfo:selectedInfo});
      if(constants.shouldDebug)
      {
        console.log("appInterface.onDateClick",selectionInfo);
      }
      
    }
    appInterface.logout=function()
    {
      localStorage.setItem(constants.localStorageTokenKey,null);
      window.location.reload(false);
      self.setState({isLoggedIn:false});
    }
    appInterface.openSideBar=function()
    {
      self.setState({sideBarOpenClass:"open"});
    }
    appInterface.closeSideBar=function()
    {
      self.setState({sideBarOpenClass:""});
    }
    appInterface.showRemindPasswordScreen=function()
    {
      self.setState({shouldShowForgotPassword:true});
    }
    appInterface.showLoginScreen=function()
    {
      self.setState({shouldShowForgotPassword:false});
    }
    appInterface.onButDateNextClick=function()
    {

      self.daysToAddToSelectedDate(1);
    }
    appInterface.onButDatePrevClick=function()
    {

      self.daysToAddToSelectedDate(-1);
    }
    appInterface.onDidChangeMonth=function(date)
    {
      self.updateCurrentMonthStateFromDate(date);
      self.setState({monthDate:date});
    }
    appInterface.onButRouteSelected=function()
    {
      var selectedInfo={...self.defaultRouteInfo};
      selectedInfo.selectedDate=self.state.selectedInfo.selectedDate;
      self.setState({modalShowFormSelectType:false,modalShowFormInput:true,selectedInfo:selectedInfo,update:self.state.update+1});
    }
    appInterface.onButExpenseSelected=function()
    {
      var selectedInfo={...self.defaultExpenseInfo};
      selectedInfo.selectedDate=self.state.selectedInfo.selectedDate;
      self.setState({modalShowFormSelectType:false,modalShowFormInput:true,selectedInfo:selectedInfo,update:self.state.update+1});
    }
    appInterface.onDataReceived=function(data)
    {
      self.handleReceivedData(data);
    }
    appInterface.requestSaveEvent=function(event)
    {
      self.saveData(event)
    }
    appInterface.requestSubmitMonth=function(event)
    {
      self.submitMonth(event)
    }
    appInterface.onEventClick=function(selectedEventInfo)
    {
        self.setState({      
          modalShowFormSelectType:false,
          modalShowFormInput:true,
          selectedInfo:selectedEventInfo,
          update:self.state.update+1});
    }
    appInterface.onButCloseMonthPress=function()
    {
      var newSelectedInfo={...self.defaultCloseMonthInfo};
      newSelectedInfo.alias=self.state.selectedMonth.alias;
      
      if(self.state.selectedMonth.timestamp_start)
      {
        newSelectedInfo.timestamp_start=self.state.selectedMonth.timestamp_start;
        newSelectedInfo.selectedDate=new Date(newSelectedInfo.timestamp_start*1000);
      }
      else
      {
        newSelectedInfo.timestamp_start=helper.getDateStartFromMonthAlias(self.state.selectedMonth.alias);
        newSelectedInfo.selectedDate=new Date(newSelectedInfo.timestamp_start)
      }

      
      newSelectedInfo.excess=self.state.selectedMonth.excess;
      newSelectedInfo.min_vehicle_odometer=dataMan.getMinimumOdometerInfo(self.state.selectedMonth,self);
      newSelectedInfo.vehicle_odometer=0;//newSelectedInfo.min_vehicle_odometer;
      newSelectedInfo.compensation=self.state.selectedMonth.compensation;
      newSelectedInfo.hasWithReplacementCar=self.state.selectedMonth.hasWithReplacementCar;

      self.setState({      
        modalShowFormSelectType:false,
        modalShowFormInput:true,
        selectedInfo:newSelectedInfo,
        update:self.state.update+1});
    }
    appInterface.onButDownloadMonthPress=function()
    {
        var address=constants.apiAddressRequestDownloadMonth;
        address=address+"?token="+self.token+"&month_id="+self.state.selectedMonth.id;
        window.open(address);
    }
    appInterface.updateSelectedInfoKeyValue=function(key,value,callback)
    {
      var selectedInfo={...self.state.selectedInfo};
      selectedInfo[key]=value;
      self.setState({selectedInfo:selectedInfo},callback);
    }
    
    
  }
  showAlert(alertMessage)
  {
    let alertBut0Title = "Εντάξει";
    this.setState({
      shouldShowSimpleAlert: true,
      alertMessage: alertMessage,
      alertBut0Title: alertBut0Title
    });
  }
  hasClosedMonths()
  {
    for (const [key,month] of Object.entries(this.state.months)) {
      if(month.locked)
      {
        return true;
      }
    }
    return false;
  }
  updateCurrentMonthStateFromDate(date)
  {
    var monthAlias=helper.getMonthAlias(date);
    var month=this.state.months[monthAlias];
    var now= new Date();
    var nowMonthAlias=helper.getMonthAlias(date);
    var dateTimeStamp=Math.floor(date.getTime()/1000);
    if(!month)
    {
      
      month={...this.defaultMonth};
      month.alias=monthAlias;
      var found=false;

      for(var i=0;i<this.state.vehicles.length;i++)
      {
        var vehicle=this.state.vehicles[i];
        if( dateTimeStamp>=vehicle.timestamp_start && dateTimeStamp<vehicle.timestamp_end )
        {
          found=true;
          month.vehicle=vehicle;
          month.vehicle_odometer=vehicle.odometer_current;
          break;
        }
      }
      if(!found && this.state.lastVehicle && this.state.firstVehicle)
      {
        
        var timeStampLastVehicle=this.state.lastVehicle.timestamp_end
        if(dateTimeStamp>=timeStampLastVehicle)
        {
            month.vehicle=this.state.lastVehicle;
            month.vehicle_odometer=this.state.lastVehicle.odometer_current;
        }
        else
        {
            month.vehicle=this.state.firstVehicle;
            month.vehicle_odometer=this.state.firstVehicle.odometer_initial;
        }
      }
      
    }
    var callBack=null;
    if(month.locked && !month.hasBeenReceived)
    {
      if(!month.allEvents || month.allEvents.length==0)
      {
        callBack=()=>{
          if(this.state.isLoading==false)
          {
            this.getData()
          }
          
        };
      }
    }

    this.setState({selectedMonth:month,events:month.allEvents},callBack);
  }

  async submitMonth(event)
  {

    this.setState({isLoading:true});
    try{
            /*
            {
                "token":"23klsdjfksjncljkhj32iu4234",
                "alias":"202107",
                "timestamp_start":1625086800,
                "vehicle_odometer":234444
            }
            */


      var postObj={};
      postObj.token=this.token;
      postObj.alias=event.alias;
      postObj.timestamp_start=event.timestamp_start;
      postObj.vehicle_odometer=event.vehicle_odometer;

      if(constants.shouldDebug)
      {
        console.log(JSON.stringify(postObj));
      }
      var data= await helper.postData(constants.apiAddressSubmitMonth,postObj);
      this.setState({isLoading:false});
      if(constants.shouldDebug)
      {
        console.log("data:",data);
      }

      if(data.status==="success")
      {
          this.appInterface.onDataReceived(data);
          if(event.hasWithReplacementCar)
          {
            setTimeout(()=>{

              this.setState({alertMessage:this.submitMonthWithReplacementCarMessage,alertBut0Title:"Εντάξει",shouldShowSimpleAlert:true});
            },1);
          }

          
      }
      else
      {
        this.setState({isLoading:false});
        
        this.showAlert(<span className="red">{this.getErrorMessageFromData(data)}</span>);

      }
    }
    catch(error)
    {
      this.setState({isLoading:false});
      this.showAlert(this.generalRequestError);
      
      console.log("onButLoginPress error:",error.message);
    }
  }
  getErrorMessageFromData(data)
  {
    if(data.message)
    {
      return data.message;
    }
    if(data.errors)
    {
      var message="";
      var sComma="";
      for(let i=0;i<data.errors.length;i++)
      {
        message=message+sComma+data.errors[i];
        sComma=", ";
      }
      return message;
    }
  }
  async saveData(event)
  {

    this.setState({isLoading:true});
    try{
            
      var postObj={};
      postObj.token=this.token;
      postObj.event=dataMan.extractEventToSave(event);
      postObj.event.user_id=this.user.id;
      postObj.event.vehicle_id=this.user.vehicle_id;
      postObj.events_only_unlocked=1;
      if(constants.shouldDebug)
      {
        console.log(JSON.stringify(postObj));
      }
      
      
      var data= await helper.postData(constants.apiAddressSave,postObj);
      this.setState({isLoading:false});
      if(constants.shouldDebug)
      {
        console.log("data:",data);
      }

      if(data.status==="success")
      {
          this.appInterface.onDataReceived(data);
      }
      else
      {
        this.showAlert(<span className="red">{this.getErrorMessageFromData(data)}</span>);
          this.setState({error:data.message});
      }
    }
    catch(error)
    {
      this.setState({isLoading:false});
      this.showAlert(this.generalRequestError);
      console.log("onButLoginPress error:",error);
    }
  }
  async getData()
  {
    console.log(this.state);
    this.setState({isLoading:true});
    try{

      var postObj={};
      var currentMonthAlias="";
      if(this.state.selectedMonth && this.state.selectedMonth.alias)
      {
        currentMonthAlias=this.state.selectedMonth.alias;
      }
      else
      {
        currentMonthAlias=helper.getMonthAlias(new Date());

      }
      postObj.events_timestamp_from=helper.getDateStartFromMonthAlias(currentMonthAlias);
      var nextMonthAlias=helper.getNextMonthAliasFromAlias(currentMonthAlias);
      postObj.events_timestamp_to=helper.getDateStartFromMonthAlias(nextMonthAlias);

      postObj.token=this.token;
      if(constants.shouldDebug)
      {
        console.log("data:",constants.apiAddressData,JSON.stringify(postObj));
      }
      var data= await helper.postData(constants.apiAddressData,postObj);
      this.setState({isLoading:false});
      
      if(data.status==="success")
      {
          this.appInterface.onDataReceived(data);
      }
      else
      {
        if(data.errors[0]==="Invalid User")
        {
          localStorage.setItem(constants.localStorageTokenKey,"");
          this.token=null;
          this.setState({isLoggedIn:false});
        }
        return;

      }
    }
    catch(error)
    {
      this.setState({isLoading:false});
      if(constants.shouldDebug)
      {
        console.log("onButLoginPress error:",error);
      }
    }
    
  }
  componentDidMount() {
    if(this.state.isLoggedIn)
    {
      this.getData();
    }
    
  }

  handleReceivedData(data)
  {
    dataMan.handleReceivedData(data,this);

  }
  
  
  daysToAddToSelectedDate(daysToAdd)
  {
      var selectedInfoCopy = {...(this.state.selectedInfo)};
      var resultDate = new Date(selectedInfoCopy.selectedDate);
      
      resultDate.setDate(resultDate.getDate() + daysToAdd);
      var nextDateMonthAlias=helper.getMonthAlias(resultDate);
      if(this.state.months[nextDateMonthAlias])
      {
        var month=this.state.months[nextDateMonthAlias];
        if(month.locked)
        {
          return;
        }
      }
      
      selectedInfoCopy.selectedDate=resultDate;
      this.setState({selectedInfo:selectedInfoCopy})
  }
  handAlertSimpleClose()
  {
    this.setState({ shouldShowSimpleAlert: false });
  }

  render()
  {
    return (
      <div className="App">
      

      {this.getMainContent()}
      {this.getLoadingScreen()}
      <Modal
          show={this.state.shouldShowSimpleAlert}
          onHide={() => this.setState({ shouldShowSimpleAlert: false })}
          centered
        >
          <Modal.Body>{this.state.alertMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="bg_blue"
              onClick={() => this.handAlertSimpleClose()}
            >
              {this.state.alertBut0Title}
            </Button>
            
          </Modal.Footer>
        </Modal>
      </div>
      
    );
  }

  getMainContent()
  {
    if(this.state.isLoggedIn===true)
    {
      return(
        <>
        <MainHeader
        appInterface={this.appInterface}
        selectedMonth={this.state.selectedMonth}
        user={this.state.user}
        firstOpenMonthAlias={this.state.firstOpenMonthAlias}
        />
        <div className="calendar_container">
          <CalendarWrapper 
            events={this.state.events}
            appInterface={this.appInterface}
            selectedMonth={this.state.selectedMonth}
          />
        </div>
        
        <FormSelectTypeModal
          appInterface={this.appInterface}
          selectedInfo={this.state.selectedInfo}
          show={this.state.modalShowFormSelectType}
          onHide={() => this.setState({modalShowFormSelectType:false})}
        />
        <FormInputModal
          appInterface={this.appInterface}
          selectedInfo={this.state.selectedInfo}
          show={this.state.modalShowFormInput}
          update={this.state.update}
          onHide={() => this.setState({modalShowFormInput:false})}
        />
        <SideBar
            appInterface={this.appInterface} open={this.state.sideBarOpenClass}
        >

        </SideBar>
        </>
      )
    }
    else
    {
      if(this.state.shouldShowForgotPassword)
      {
        return(
          <>
            <RemindPasswordScreen
              appInterface={this.appInterface}
            />
          </>
        )
      }
      else
      {
        return(
          <>
            <LoginScreen
              appInterface={this.appInterface}
            />
          </>
        )
      }
      
    }
  }
  getLoadingScreen()
  {
      if(this.state.isLoading)
      {
        return(<div className="loading_screen">
        <img src={iconLoading} alt=""/>
        </div>)
      }
      else
      {
        return (<></>)
      }
  }

  
}





export default App;
