import React from 'react';
import './SideBar.scss';

import iconLogout from './images/icon_logout.svg';
import iconExit from './images/icon_exit.svg';
import iconReplaceCar from './images/icon_replace_car.svg';
import * as constants from  './constants';
import * as helper from  './helper';

import iconLoading from './images/loading_small_transparent.gif';

export default class SidePar extends React.Component {

    constructor(props)
    {
      super(props);
    }
    closeMe()
    {
        this.props.appInterface.closeSideBar();
    }
    onButLogoutPress()
    {
        this.props.appInterface.logout();
    }


    render()
    {
      return (
        <div className={"side_bar_container "+this.props.open}>
            <button className="but_form_exit" onClick={this.closeMe.bind(this)}>  
                <img src={iconExit} alt=""/>
                
            </button>
            <a href="assets/replacement_car.xlsx" download className="but_change_car side_bar_button gray"><div className="img_container"><img src={iconReplaceCar} alt=""/></div><span>Φόρμα Αυτοκινήτου<br/>Αντικατάστασης</span></a>
            <div className="seperator bg_gray"></div> 
            <button className="but_logout side_bar_button gray" onClick={this.onButLogoutPress.bind(this)}><div className="img_container"><img src={iconLogout} alt=""/></div><span>Έξοδος</span></button>
        </div>
      );
    }
    

}