

export const monthFullNames =["Ιανουάριος","Φεβρουάριος","Μάρτιος","Απρίλιος","Μάιος","Ιούνιος","Ιούλιος","Αύγουστος","Σεπτέμβριος","Οκτώβριος","Νοέμβριος","Δεκέμβριος"]
export const monthFullFromNames =["Ιανουαρίου","Φεβρουαρίου","Μαρτίου","Απριλίου","Μαΐου","Ιουνίου","Ιουλίου","Αυγούστου","Σεπτεμβρίου","Οκτωβρίου","Νοεμβρίου","Δεκεμβρίου"]


export function formatNumber0Digits(num)
{
    num=parseFloat(num);
    num=Math.round(num);
    var numStr=num+"";


    return numStr;
}

//export const baseAddress="http://10.0.0.12/servier/fleetmanagement/api/DBActions.php/"

export const baseAddress="api/DBActions.php/";


export var apiAddressData=baseAddress+"data";
export var apiAddressSave=baseAddress+"save_event";
export var apiAddressSubmitMonth=baseAddress+"submit_month";
export var apiAddressRequestResetPassword=baseAddress+"request_reset_password";
export var apiAddressRequestDownloadMonth=baseAddress+"download_month";

export var localStorageTokenKey="fleetmanagement_token_key";

export var shouldDebug=true;


export var defaultMonth={
    total_expenses:0,
    total_distance:0,
    excess:0,
    compensation:0
  }

export var defaultRouteInfo={
    formTitle:"Προσθήκη Διαδρομής / Διαμονής",
    formBut0Title:"ΑΠΟΘΗΚΕΥΣΗ",
    formBut0Action:"save",
    formBut1Title:"ΑΚΥΡΟ",
    formBut1Action:"cancel",
    selectedDate:new Date(),
    dateType:"day",
    type:"route",
    
    distance:0,
    destination:"",
    didStay:false,
    canChangeDate:true
    
  };
export var defaultExpenseInfo={
    formTitle:"Προσθήκη Δαπάνης",
    formBut0Title:"ΑΠΟΘΗΚΕΥΣΗ",
    formBut0Action:"save",
    formBut1Title:"ΑΚΥΡΟ",
    formBut1Action:"cancel",
    selectedDate:new Date(),
    dateType:"day",
    type:"expense",
    cost:0,
    amount:0,
    canChangeDate:true
    
  };
  export var defaultCloseMonthInfo={
    formTitle:"Κλείσιμο Μήνα",
    formBut0Title:"ΥΠΟΒΟΛΗ",
    formBut0Action:"save",
    formBut1Title:"ΑΚΥΡΟ",
    formBut1Action:"cancel",
    selectedDate:new Date(),
    dateType:"month",
    type:"month_close",
    vehicle_odometer:0,
    timestamp:Math.floor((new Date()).getTime()/1000),
    excess:0,
    canChangeDate:false
  }




