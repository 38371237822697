import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./FormInputModal.scss";

import arrowNext from "./images/arrow_next.svg";
import arrowPrev from "./images/arrow_prev.svg";
import iconExit from "./images/icon_exit.svg";
import * as constants from "./constants";
import * as helper from "./helper";

import iconMoneyGray from "./images/icon_money_small_gray.svg";
import iconGasGray from "./images/icon_gas_gray.svg";

import iconDistanceGray from "./images/icon_distance_gray.svg";
import iconEditGray from "./images/icon_edit_gray.svg";
import iconBedGray from "./images/icon_bed_small_gray.svg";
import iconChecked from "./images/icon_checked.svg";
import iconUnchecked from "./images/icon_unchecked.svg";

import iconCheckedRed from "./images/icon_checked_red.svg";
import iconUncheckedRed from "./images/icon_unchecked_red.svg";

import iconSpeedometer from "./images/icon_speedometer.svg";
import iconHumanFlag from "./images/icon_human_flag.svg";
import iconReplaceCar from './images/icon_replace_car_red.svg';

export default class FormInputModal extends React.Component {
  constructor(props) {
    super(props);

    var state = this.getStateFromProps(props);
    this.state = state;
    this.update = props.update;
    this.appInterface = props.appInterface;
    console.log("FormInputModal props");
    console.log(props);
  }
  render() {
    return (
      <>
        <Modal
          {...(({ show, onHide }) => ({ show, onHide }))(this.props)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="form_input_container">
              <h1>{this.props.selectedInfo.formTitle}</h1>
              <button className="but_form_exit" onClick={this.props.onHide}>
                <img src={iconExit} alt="" />
              </button>

              {this.getInputDateControls()}
              {this.getFormContents()}

              <div className="form_input_footer">{this.getFooterButtons()}</div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.shouldShowAlert}
          onHide={() => this.setState({ shouldShowAlert: false })}
          centered
        >
          <Modal.Body>{this.state.alertMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="bg_blue"
              onClick={() => this.handAlertClose(0)}
            >
              {this.state.alertBut0Title}
            </Button>
            <Button
              variant="danger"
              className="bg_red"
              onClick={() => this.handAlertClose(1)}
            >
              {this.state.alertBut1Title}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.shouldShowSimpleAlert}
          onHide={() => this.setState({ shouldShowSimpleAlert: false })}
          centered
        >
          <Modal.Body>{this.state.alertMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="bg_blue"
              onClick={() => this.handAlertSimpleClose(0)}
            >
              {this.state.alertBut0Title}
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  getFooterButtons() {
    if (this.props.selectedInfo.locked === 1) {
      return <></>;
    }
    return (
      <>
        <button
          className="form_input_button button_input_modal bg_blue"
          onClick={() => this.onButFooterPress(0)}
        >
          {this.props.selectedInfo.formBut0Title}
        </button>
        <button
          className="form_input_button button_input_modal bg_red"
          onClick={() => this.onButFooterPress(1)}
        >
          {this.props.selectedInfo.formBut1Title}
        </button>
      </>
    );
  }
  onButFooterPressOnCloseMonth(buttonIndex){
    if (buttonIndex === 0) {
      let alertBut0Title = "Ακυρο";
      let alertBut1Title = "Υποβολή";
      let alertMessage =
          "Είστε σίγουρος/η ότι θέλετε να υποβάλετε τον μήνα.";

      if(this.props.selectedInfo.vehicle_odometer<this.props.selectedInfo.min_vehicle_odometer)
      {
        alertMessage=<span>Από τις διαδρομές που έχετε δηλώσει, η ελάχιστη τιμή χιλιομέτρων του οχήματος πρέπει να είναι <strong class="red">{this.props.selectedInfo.min_vehicle_odometer}</strong> km</span>;
        this.showAlert(alertMessage);
        return;
        
      }
      


      this.setState({
        shouldShowAlert: true,
        alertMessage: alertMessage,
        alertBut0Title: alertBut0Title,
        alertBut1Title: alertBut1Title,
      });
    }
    else
    {

    }
    if (this.props.selectedInfo.formBut1Action === "cancel" && buttonIndex===1) {
      this.props.onHide();
    }
  }
  showAlert(alertMessage)
  {
    let alertBut0Title = "Εντάξει";
    this.setState({
      shouldShowSimpleAlert: true,
      alertMessage: alertMessage,
      alertBut0Title: alertBut0Title
    });
  }
  onButFooterPress(buttonIndex) {
    if(this.props.selectedInfo.type === "month_close")
    {
      this.onButFooterPressOnCloseMonth(buttonIndex)
      return;
    }
    
    if (buttonIndex === 0) {
      
  
        this.props.onHide();
        this.props.appInterface.updateSelectedInfoKeyValue("action", "save");
        this.props.appInterface.requestSaveEvent(this.props.selectedInfo);

    } else {
      
      if (this.props.selectedInfo.formBut1Action === "delete") {
        let alertMessage = "";
        let alertBut0Title = "Ακυρο";
        let alertBut1Title = "Διαγραφή";
        if (this.props.selectedInfo.type === "route") {
          alertMessage =
            "Είστε σίγουρος/η ότι θέλετε να διαγράψετε την διαδρομή/διαμονή;";
        } else if (this.props.selectedInfo.type === "expense") {
          alertMessage =
            "Είστε σίγουρος/η ότι θέλετε να διαγράψετε την δαπάνη;";
        }

        this.setState({
          shouldShowAlert: true,
          alertMessage: alertMessage,
          alertBut0Title: alertBut0Title,
          alertBut1Title: alertBut1Title,
        });
      }
    }

    if (this.props.selectedInfo.formBut1Action === "cancel" && buttonIndex===1) {
      this.props.onHide();
    }
  }
  handAlertSimpleClose(buttonIndex)
  {
    this.setState({ shouldShowSimpleAlert: false });
  }
  handAlertClose(buttonIndex) {
    console.log("handAlertClose", buttonIndex);
    this.setState({ shouldShowAlert: false });
    if (
      this.props.selectedInfo.type === "route" ||
      this.props.selectedInfo.type === "expense"
    ) {
      if (buttonIndex === 1) {
        this.props.appInterface.updateSelectedInfoKeyValue(
          "action",
          "delete",
          () => {
            this.props.appInterface.requestSaveEvent(this.props.selectedInfo);
            this.props.onHide();
          }
        );
      }
    }
    else if(this.props.selectedInfo.type==="month_close")
    {
      if (buttonIndex === 1) {
        this.props.appInterface.requestSubmitMonth(this.props.selectedInfo);
        this.props.onHide();
      }
    }
  }
  componentDidUpdate() {
    if (this.props.update !== this.update) {
      //var newState=this.getStateFromProps(this.props);
      //this.setState(newState);
      //this.update=this.props.update;
    }
  }
  getStateFromProps(nextProps) {
    var newState = { ...nextProps.selectedInfo, shouldShowAlert: false };

    return newState;
  }

  getInputDateControls() {
    if (this.props.selectedInfo.canChangeDate === true) {

      var butDatePrev=<button className="but_prev" onClick={() => this.onButDatePrevClick()}><img src={arrowPrev} alt="" /></button>
      
      var butDateNext=<button className="but_next" onClick={() => this.onButDateNextClick()}> <img src={arrowNext} alt="" /></button>
      
      var selectedMonthAlias=helper.getMonthAlias(this.props.selectedInfo.selectedDate);
      var nextDate=helper.getDateByAddingDaysToDate(1,this.props.selectedInfo.selectedDate);
      var nextMonthAlias=helper.getMonthAlias(nextDate);

      if(nextMonthAlias!=selectedMonthAlias)
      {
        butDateNext=<></>
      }
      
      var prevDate=helper.getDateByAddingDaysToDate(-1,this.props.selectedInfo.selectedDate);
      var prevMonthAlias=helper.getMonthAlias(prevDate);
      if(prevMonthAlias!=selectedMonthAlias)
      {
        butDatePrev=<></>
      }
    

      return (
        <div className="form_input_date_controls">
          {butDatePrev}
          {this.getCurrentDateDisplay()}
          {butDateNext}
        </div>
      );
    } else {
      return (
        <div className="form_input_date_controls">
          {this.getCurrentDateDisplay()}
        </div>
      );
    }
  }
  getFormContents() {
    if (this.props.selectedInfo.type === "expense") {
      return this.getExpenseFormContents();
    } else if (this.props.selectedInfo.type === "route") {
      return this.getRouteFormContents();
    } else if (this.props.selectedInfo.type === "month_close") {
      return this.getCloseMonthFormContents();
    }

    return <span></span>;
  }
  getCloseMonthFormContents() {
    return (
      <div className="form_input_modal_close_month">
        <img src={iconSpeedometer} width="90" alt="" />
        <Form.Label className="gray">
          <strong>Χιλιόμετρα αυτοκινήτου τέλος του μήνα (km)</strong>
        </Form.Label>
        <Form.Control
          className="blue input_total_car_distance"
          type="number"
          value_type="integer"
          value_key="vehicle_odometer"
          placeholder="0,00"

          defaultValue={this.props.selectedInfo.vehicle_odometer}
          onBlur={this.onHandleInputChange.bind(this)}
        />
        <span>Από τις διαδρομές που έχετε δηλώσει, η ελάχιστη τιμή χιλιομέτρων του οχήματος είναι <strong class="red">{this.props.selectedInfo.min_vehicle_odometer}</strong> km</span>
        <div className="overlimit_container bg_lightgray">
          <img src={iconHumanFlag} alt="" />
          <Form.Label className="overlimit_excess_label gray">
            <strong>Υπέρβαση (€)</strong>
          </Form.Label>
          <span className="overlimit_excess red">
            {helper.formatNumber2Digits(this.props.selectedInfo.excess)}
          </span>
        </div>
      </div>
    );
  }
  getExpenseFormContents() {
    return (
      <div className="form_input_modal">
        <Form.Group className="form_input_group" controlId="formCost">
          <Form.Label className="gray">
            <img src={iconMoneyGray} alt="" />
            &nbsp;&nbsp;<strong>Ποσό (€)</strong>
          </Form.Label>
          <Form.Control
            className="green"
            type="number"
            value_type="float"
            value_key="cost"
            lang="en"
            placeholder="0,00"
            defaultValue={helper.formatNumber2DigitsWithDot(
              this.props.selectedInfo.cost
            )}
            onBlur={this.onHandleInputChange.bind(this)}
            disabled={this.props.selectedInfo.locked === 1}
      
          />
        </Form.Group>
        <Form.Group className="form_input_group form_input_group_checkboxes" controlId="formCost">
          <span>&nbsp;</span>
        <button
            className="form_but_input_check"
            onClick={() => {
                if(this.props.selectedInfo.locked!==1)
                {
                    this.onWithReplacementCarPress()
                }
            }
            }
          >
            <img src={iconReplaceCar} alt="" />
            <span>&nbsp;</span>
            <span className="gray text-left">

              <strong> Με αυτοκίνητο <br/> αντικατάστασης</strong>
            </span>{" "}
            &nbsp;&nbsp;
            {this.getReplacementCarIcon()}
          </button>
        </Form.Group>

        <Form.Group className="form_input_group" controlId="formValue">
          <Form.Label className="gray">
            <img src={iconGasGray} alt="" />
            &nbsp;&nbsp;<strong>Λίτρα (lt)</strong>
          </Form.Label>
          <Form.Control
            className="orange"
            type="number"
            value_type="float"
            value_key="amount"
            lang="en"
            placeholder="0,00"
            defaultValue={helper.formatNumber2DigitsWithDot(
              this.props.selectedInfo.amount
            )}
            onBlur={this.onHandleInputChange.bind(this)}
            disabled={this.props.selectedInfo.locked === 1}
          />
        </Form.Group>
      </div>
    );
  }

  getRouteFormContents() {
    return (
      <div className="form_input_modal">
        <Form.Group className="form_input_group" controlId="formCost">
          <Form.Label className="gray">
            <img src={iconDistanceGray} alt="" />
            &nbsp;&nbsp;<strong>Απόσταση (km)</strong>
          </Form.Label>
          <Form.Control
            className="blue"
            type="number"
            value_type="integer"
            value_key="distance"
            placeholder="0,00"
            defaultValue={this.props.selectedInfo.distance}
            onBlur={this.onHandleInputChange.bind(this)}
            disabled={this.props.selectedInfo.locked === 1}
          />
        </Form.Group>

        <Form.Group className="form_input_group form_input_group_checkboxes" controlId="formCost">
          
          <button
            className="form_but_input_check"
            onClick={() => {
                if(this.props.selectedInfo.locked!==1)
                {
                    this.onDidStayPress()
                }
            }
            }
          >
            <span className="gray">
              <img src={iconBedGray} alt="" />
              &nbsp;&nbsp;
              <strong>Διαμονή</strong>
            </span>{" "}
            &nbsp;&nbsp;
            {this.getBedCheckIcon()}
          </button>

          <button
            className="form_but_input_check"
            onClick={() => {
                if(this.props.selectedInfo.locked!==1)
                {
                    this.onWithReplacementCarPress()
                }
            }
            }
          >
            <img src={iconReplaceCar} alt="" />
            <span>&nbsp;</span>
            <span className="gray text-left">

              <strong>Με αυτοκίνητο <br/> αντικατάστασης</strong>
            </span>{" "}
            &nbsp;&nbsp;
            {this.getReplacementCarIcon()}
          </button>
        </Form.Group>



        <Form.Group className="form_input_group" controlId="formValue">
          <Form.Label className="gray">
            <img src={iconEditGray} alt="" />
            &nbsp;&nbsp;<strong>Προορισμός</strong>
          </Form.Label>
          <Form.Control
            className="black"
            type="text"
            value_type="text"
            value_key="destination"
            placeholder=""
            maxLength="100"
            defaultValue={this.props.selectedInfo.destination}
            onBlur={this.onHandleInputChange.bind(this)}
            disabled={this.props.selectedInfo.locked === 1}
          />
        </Form.Group>
      </div>
    );
  }
  onDidStayPress() {
    this.props.appInterface.updateSelectedInfoKeyValue(
      "didStay",
      !this.props.selectedInfo.didStay
    );

    //this.setState({didStay:!this.state.didStay});
  }
  onWithReplacementCarPress(){
    this.props.appInterface.updateSelectedInfoKeyValue(
      "withReplacementCar",
      !this.props.selectedInfo.withReplacementCar
    )
  }
  onHandleInputChange(event) {
    var txtInput = event.target;
    var valueKey = txtInput.getAttribute("value_key");
    var valueType = txtInput.getAttribute("value_type");
    var valueMin=parseInt(txtInput.getAttribute("value_min"));
    if(isNaN(valueMin))
    {
      valueMin=0;
    }

    var value = txtInput.value;
    console.log(
      "onHandleInputChange value type:" + valueKey + " event:",
      event
    );
    var num = 0;
    if (valueType === "integer") {
      var numStr = value + "";
      numStr = numStr.replaceAll(",", ".");
      num = parseFloat(numStr);
      value = Math.round(num);
      if(value<valueMin)
      {
        value=valueMin;
      }
      txtInput.value = value;
    } else if (valueType === "float") {
      num = value;
      if (typeof value === "string") {
        value = value.replaceAll(",", ".");
        num = parseFloat(value);
      }
      num = Math.round(num * 100) / 100;
      value = num;
      txtInput.value = helper.formatNumber2DigitsWithDot(value);
    }

    var statePart = {};
    statePart[valueKey] = value;
    this.props.appInterface.updateSelectedInfoKeyValue(valueKey, value);

    //this.setState(statePart)
  }
  onButDateNextClick() {
    console.log("onButNextClick");
    this.daysToAddToSelectedDate(1);
  }
  onButDatePrevClick() {
    console.log("onButPrevClick");
    this.daysToAddToSelectedDate(-1);
  }
  daysToAddToSelectedDate(daysToAdd) {
    var resultDate = new Date(this.props.selectedInfo.selectedDate);
    resultDate.setDate(resultDate.getDate() + daysToAdd);
    this.props.appInterface.updateSelectedInfoKeyValue(
      "selectedDate",
      resultDate
    );
    //this.setState({selectedDate:resultDate})
  }
  getCurrentDateDisplay() {
    var date = this.props.selectedInfo.selectedDate;
    var day = date.getDate();
    var year = date.getFullYear();
    var monthFullName = "";
    if (this.props.selectedInfo.dateType === "month") {
      monthFullName = constants.monthFullNames[date.getMonth()];

      return (
        <span>
          <span>
            <strong>{monthFullName}</strong> {year}{" "}
          </span>
        </span>
      );
    } else {
      monthFullName = constants.monthFullFromNames[date.getMonth()];
      return (
        <span>
          <span>
            <strong>
              {day} {monthFullName}
            </strong>{" "}
            {year}{" "}
          </span>
        </span>
      );
    }
  }
  getReplacementCarIcon()
  {
    if (this.props.selectedInfo.withReplacementCar === true) {
      return <img src={iconCheckedRed} alt="" />;
    } else {
      return <img src={iconUncheckedRed} alt="" />;
    }
  }
  getBedCheckIcon() {
    if (this.props.selectedInfo.didStay === true) {
      return <img src={iconChecked} alt="" />;
    } else {
      return <img src={iconUnchecked} alt="" />;
    }
  }
}
