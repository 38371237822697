import React from 'react';
import './RemindPasswordScreen.scss';

import iconRoute from './images/icon_route.svg';
import * as constants from  './constants';
import * as helper from  './helper';

import iconLoading from './images/loading_small_transparent.gif';

export default class RemindPasswordScreen extends React.Component {

    constructor(props)
    {
      super(props);
      this.state={};
      this.state.error=" ";
      this.state.isLoading=false;
      this.state.message="";
      
    }

    render()
    {
      return (
        <div className="remind_password_screen">
            <img className="login_logo" src={iconRoute} alt=""/>
            <span className="app_title gray">Fleet Management</span>
            <span className="app_subtitle gray">Υπενθύμιση κωδικού</span>
            {this.checkToShowMessage()}
            <a className="login_but_back link-secondary" onClick={()=>this.onButBackPress()}><u> Πίσω</u></a>
            {this.getLoadingScreen()}
  
        </div>
      );
    }
    checkToShowMessage()
    {
      if(this.state.message && this.state.message.length>0)
      {
        return(<span className="app_subtitle gray">{this.state.message}</span>);
      }
      else
      {
          return(
          <>
          <div className="input_email_container">
                <div className="login_input_label gray">EMAIL</div>
                <input className="blue" type="email" name="email" id="email" autoComplete="off" onChange={()=>this.onTxtInputChange()} onBlur={this.onHandleInputChange.bind(this)} value_key="username"></input>
            </div>
            <div className="seperator bg_gray"></div>

            
            <span className="login_error red">{this.state.error}</span>
            <button className="login_but_submit bg_blue" onClick={()=>this.onButSubmitPress()}> ΥΠΟΒΟΛΗ </button>
          </>
          )
      }
    }
    onHandleInputChange(event)
    {
      var txtInput = event.target;
      var valueKey = txtInput.getAttribute("value_key");
      var value = txtInput.value;
      var statePart = {};
    statePart[valueKey] = value;
      this.setState(statePart);
    }
    onTxtInputChange()
    {
      this.setState({error:" "});
    }
    
    onButBackPress()
    {
      this.props.appInterface.showLoginScreen();
    }
    onButSubmitPress()
    {
        this.sendRequest();
    }

    onTxtInputKeyDown(event)
    {
      if(event.keyCode===13)
      {
        this.sendRequest();
      }
    }
    
    async sendRequest()
    {
      this.setState({error:" ",isLoading:true});

      try{
        
        var postObj={username:this.state.username,action:"password_reset"};
        console.log(constants.apiAddressData,JSON.stringify(postObj));
        var data= await helper.postData(constants.apiAddressRequestResetPassword,postObj);
        this.setState({isLoading:false});
        console.log("data:",data);

        if(data.status==="success")
        {
            this.setState({message:data.message});
        }
        else
        {
            this.setState({error:data.errors[0]});
        }

      }
      catch(error)
      {
        this.setState({error:error.message,isLoading:false});
       
        console.log("onButSubmitPress error:",error);
      }
      
    }

    getLoadingScreen()
    {
        if(this.state.isLoading)
        {
          return(<div className="loading_screen">
          <img src={iconLoading} alt=""/>
          </div>)
        }
        else
        {
          return (<></>)
        }
    }
}