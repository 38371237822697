import React from 'react';
import './LoginScreen.scss';

import iconRoute from './images/icon_route.svg';
import * as constants from  './constants';
import * as helper from  './helper';

import iconLoading from './images/loading_small_transparent.gif';

export default class LoginScreen extends React.Component {

    constructor(props)
    {
      super(props);
      this.state={};
      this.state.error=" ";
      this.state.isLoading=false;
    }

    render()
    {
      return (
        <div className="login_screen">
            <img className="login_logo" src={iconRoute} alt=""/>
            <span className="app_title gray">Fleet Management</span>
            <div className="input_email_container">
                <div className="login_input_label gray">EMAIL</div>
                <input className="username_field blue" type="email" name="email" id="email" autoComplete="off"   value_key="username"></input>
            </div>
            <div className="seperator bg_gray"></div>

            <div className="input_email_container">
                <div className="login_input_label gray">ΚΩΔΙΚΟΣ</div>
                <input className="password_field blue" type="password" onKeyDown={this.onTxtInputKeyDown.bind(this)}   value_key="password"></input>
            </div>
            <div className="seperator bg_gray"></div>
            <span className="login_error red">{this.state.error}</span>
            <button className="login_but_submit bg_blue" onClick={()=>this.onButLoginPress()}> ΕΙΣΟΔΟΣ </button>
            <a className="login_but_forgot_password link-secondary" onClick={()=>this.onButForgotPasswordPress()}><u> Υπενθύμιση κωδικού</u></a>
            {this.getLoadingScreen()}
  
        </div>
      );
    }
    onHandleInputChange(event)
    {
      var txtInput = event.target;
      var valueKey = txtInput.getAttribute("value_key");
      var value = txtInput.value;
      var statePart = {};
      statePart[valueKey] = value;
      this.setState(statePart);
    }
    onTxtInputChange()
    {
      this.setState({error:" "});
    }
    onButForgotPasswordPress()
    {
      this.props.appInterface.showRemindPasswordScreen();
    }

    onButLoginPress()
    {
        this.sendCredentials();
        

    }

    onTxtInputKeyDown(event)
    {
      if(event.keyCode===13)
      {
        this.sendCredentials();
      }
    }

    async sendCredentials()
    {
      this.setState({error:" ",isLoading:true});

      try{
        
        var passwordField=document.querySelector(".password_field");
        var usernameField=document.querySelector(".username_field");


        var postObj={username:usernameField.value,password:passwordField.value};
        //console.log(this.state);
        //var postObj={username:this.state.username,password:this.state.password};

        //onChange={this.onHandleInputChange.bind(this)} onInput={this.onHandleInputChange.bind(this)}

        console.log(constants.apiAddressData,JSON.stringify(postObj));
        var data= await helper.postData(constants.apiAddressData,postObj);
        this.setState({isLoading:false});
        console.log("data:",data);

        if(data.status==="success")
        {
            this.props.appInterface.onDataReceived(data);
        }
        else
        {
            this.setState({error:data.errors[0]});
        }
      }
      catch(error)
      {
        this.setState({error:error.message});
        this.setState({isLoading:false});
        console.log("onButLoginPress error:",error);
      }
      
    }

    getLoadingScreen()
    {
        if(this.state.isLoading)
        {
          return(<div className="loading_screen">
          <img src={iconLoading} alt=""/>
          </div>)
        }
        else
        {
          return (<></>)
        }
    }
}